import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../layout'

Vue.use( VueRouter )

const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      { path: '/', name: 'home', component: () => import( '@/views/Home.vue' ) }
    ]
  },
  // {
  //   path: '/TermsOfService',
  //   name: 'TermsOfService',
  //   component: () => import( '../views/TermsOfService.vue' )
  // },
  {
    path: '*',
    name: '404',
    component: () => import( '../views/404.vue' )
  }
]

const router = new VueRouter( {
  mode: 'history',
  scrollBehavior ( to, from, savedPosition ) {
    if ( to.hash ) {
      return {
        selector: to.hash
      }
    }
    if ( savedPosition ) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
} )

export default router

<template>
  <div>
    <div @click="close" class="form-component">
      <h1>{{$t('contact.title')}}</h1>
      <form @submit.prevent="submitForm">
         <div class="content">
          <div style="display: flex; width: 100%; border: 1px solid #ccc; border-radius: 8px; height: 35px; background-color: white; align-items: center;">
            <div class="dropdown" @click.stop="toggleDropdown">
              <div class="selected-option">{{ selectedOption }} <img src="../assets/icon/chevron-down.svg"/></div>
              <div class="options" v-if="isOpen">
                <div class="option" v-for="(option, index) in options" :key="index" @click.stop="selectOption(option.callingCode)">{{ option.name }} {{ option.callingCode }}</div>
              </div>
            </div>
            <div style="width: 100%; overflow: hidden;">
              <input v-model="phoneNum" style="border: none; flex: 1; border-top-left-radius: 0; border-bottom-left-radius: 0; height: 10px;" type="number" placeholder="00-00-00" required />
            </div>
          </div>
          <div style="position: relative; display: flex; min-height: 35px; align-items: center; background-color: white; border-radius: 8px;">
            <input v-model="fullName" style="border: none; border-radius: 8px; height: 10px; padding: 5px;" type="text" required />
            <span v-if="!fullName" style="position: absolute; left: 5px; color: #999;">{{$t('contact.full_name')}} <span style="color: red;">*</span></span>
          </div>
          <div style="position: relative; display: flex; min-height: 35px; background-color: white; border-radius: 8px;">
            <textarea v-model="message" style="width: 100%; height: 100px;" required></textarea>
            <span v-if="!message" style="position: absolute; left: 5px;top: 3px; color: #999;">{{$t('contact.message')}} <span style="color: red;">*</span></span>
          </div>
        </div>
        <button >{{$t('contact.send')}}</button>
      </form>
    </div>
  </div>
</template>

<script>
import countryCode from '../demodata/countryCode.json'
export default {
  name: 'Form',
  data() {
    return {
      isOpen: false,
      selectedOption: '+86',
      options: countryCode,
      phoneNum: null,
      fullName: null,
      message: null
    }
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.$nextTick(() => {
          document.addEventListener('click', this.closeDropdown)
        })
      }
    },
    close() {
      if (this.isOpen === true) {
        this.isOpen = false
      }
    },
    closeDropdown(event) {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
        document.removeEventListener('click', this.closeDropdown)
      }
    },
    selectOption(option) {
      this.selectedOption = option
      this.isOpen = false
    },
    submitForm() {
      const API_URL = process.env.VUE_APP_BASE_API

      if (!this.phoneNum || !this.fullName || !this.message ) {
        console.log('Please fill in all fields')
      } else if (!this.validatePhoneNumber()) {
        this.$emit('err', { message: `&#10060; ${this.$t('contact.invalidPhone')}` })
      } else {
        // reomove + from phone number
        this.selectedOption = this.selectedOption.replace('+', '')
        try {
          fetch(API_URL + 'send/message/record/add', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              fullName: this.fullName,
              messageContent: this.message,
              phoneNum: this.selectedOption + this.phoneNum
            })
          })
            .then(response => response.json())
            .then(data => {
              this.$emit('success', { message: `&#10003; ${this.$t('contact.messageSentSuccess')}` })
              this.phoneNum = null
              this.fullName = null
              this.message = null
              this.selectedOption = '+86'
            })
        } catch (error) {
          console.error('Error creating user:', error)
        }
      }
    },
    validatePhoneNumber() {
      const regex = /^(\+\d{1,2}\s?)?1?-?\.?\s?\(?\d{3}\)?[\s.-]?\d{2,4}[\s.-]?\d{1,4}$/
      return regex.test(this.phoneNum)
    }
  }
}

</script>
<style lang="less" scoped>
@baseColor:#0F9D58;
.form-component{
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  max-width: 500px;
  h1{
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    font-weight: bold;
    color: white;
  }
  .content{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    gap: 10px;
    input{
      padding: 4px;
      border-radius: 8px;
      border: none;
    }
    input:focus{
      outline: none;
    }
    textarea{
      padding: 5px;
      border-radius: 8px;
      border: none;
      height: 70px;
    }
    textarea:focus{
      outline: none;
    }
    .dropdown {
    position: relative;
    min-width: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
  }
.selected-option {
  cursor: pointer;
  text-align: center;
  padding-left: 5px;
  display: flex;
  font-size: 12px;
  min-width: 58px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.options {
  position: absolute;
  width: 250px;
  border: 1px solid #ccc;
  margin-top: 300px;
  border-top: none;
  background-color: white;
  max-height: 250px;
  overflow: auto;
  z-index: 1;
}
.option {
  padding: 10px;
  cursor: pointer;
}
.option:hover {
  background-color: #eee;
}
  }
  button{
    margin-top: 20px;
    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: @baseColor;
    color: white;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>

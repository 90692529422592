<template>
  <div>
    <div>

    </div>
    <!-- cover section -->
    <div class="cover">
      <!-- <video ref="bannerVideo" class="video" muted loop autoplay>
        <source src="../assets/mpos-banner.mp4" type="video/mp4" />
        <p>
          Your browser doesn't support HTML5 video. Here is a
          <a href="../assets/mpos-banner.mp4">link to the video</a> instead.
        </p>
      </video> -->
      <img class="video" src="../assets/mpos-banner.png" alt="video-cover" />
      <div>
        <h1>{{$t('cover.title')}}</h1>
        <p>{{$t('cover.description')}}</p>
      </div>
    </div>
    <!-- review section -->
    <div id="0" class="about-us" ref="aboutUs">
      <div class="divider">
        <h3>{{$t('aboutUs.about_us')}}</h3>
      </div>
      <div>
        <h1>{{$t('aboutUs.about_us_desc')}}</h1>
      </div>
        <div  class="review">
            <div v-for="(item, index) in review" :key="index" >
            <h1><span>{{ item.number }}</span> {{ item.number === 100 ? '+ '+ item.unit : item.unit }} </h1>
            <h1 style="margin-top: -20px;">{{ item.description }}</h1>
            <h4>{{ item.fullServiceCoverage}}</h4>
            <p> {{ item.coverage }} </p>
        </div>
    </div>
    </div>
    <!-- Product section -->
    <div id="1" class="product" ref="products">
      <div class="divider">
        <h3>{{$t('product.title')}}</h3>
      </div>
      <div>
        <h1>{{$t('product.description')}}</h1>
      </div>
      <div>
        <product-vue class="service-item" :data="product" :colNum="5" />
      </div>
      <div >
        <product-vue class="service-item-two" :data="product2" :colNum="8"/>
      </div>
    </div>
    <!-- service section -->
    <section id="2">
      <div  class="service" >
        <div class="divider">
          <h3>{{ $t('seavices.title') }}</h3>
        </div>
        <div>
          <h1>{{ $t('seavices.description') }}</h1>
        </div>
        <div class="grid-img">
          <div v-for="(i, index) in service" :key="index" class="img">
            <img :src="getImages(i.img)" alt="">
            <div class="text-img">
              <p>{{ i.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- merchant section -->
    <div id="3" class="merchant" ref="clients">
      <div class="divider">
        <h3>{{ $t('merchant.title') }}</h3>
      </div>
      <div>
        <h1>{{ $t('merchant.description') }}</h1>
      </div>
      <div class="grid-img">
        <div v-for="(i, index) in merchant" :key="index" class="img">
          <img :src="getImages(i)" alt="">
        </div>
      </div>
    </div>
    <!-- Cambodia F&B Industry section -->
    <div class="food">
      <div style="display: flex; max-height: 150px ; width: 100%;">
        <div v-for="(i, index) in food1" :key="index">
          <img class="food-bg" :src="getImages(i)" alt="food-bg" />
        </div>
        <div class="text" style="flex: 1;">
          <h1>{{$t('food.title')}}</h1>
        </div>
        <div v-for="(i, index) in food2" :key="index">
          <img class="food-bg" :src="getImages(i)" alt="food-bg" />
        </div>
      </div>
      <div style="display: flex; max-height: 150px ">
        <div v-for="(i, index) in food3" :key="index">
          <img class="food-bg" :src="getImages(i)" alt="food-bg" />
        </div>
      </div>
      <div style="display: flex; max-height: 150px ">
        <div v-for="(i, index) in food4" :key="index">
          <img class="food-bg" :src="getImages(i)" alt="food-bg" />
        </div>
      </div>
    </div>
    <!-- send message section -->
    <div id="4" class="message" ref="contactUs">
      <div class="img">
        <img style="
        width: 100%;
        min-height: 550px;
        object-fit: cover;"
        src="../assets/image/message.jpg" alt="">
      </div>
      <div class="form">
        <Form @success="handleMessange"/>
      </div>
      <!-- Toast Message -->
      <div v-if="showToast" class="toast">
        <p v-html="message"></p>
      </div>
    </div>
    <!-- float button -->
    <!-- <a href="https://t.me/mposcambodia_sales" target="_blank" class="floating-button">
      <div class="button-content">
        <p>{{$t('footer.chat')}}</p>
        <img src="../assets/icon/telegram.svg" alt="facebook" />
      </div>
    </a> -->
  </div>
</template>

<script>
import ProductVue from '../components/Product.vue'
import Form from '../components/Form.vue'
export default {
  name: 'Home',
  components: {
    ProductVue,
    Form
  },
  props: {
    menuTxt: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      review: [
        {
          number: '10+',
          unit: 'k',
          // unit: this.$t('review.thousands'),
          description: this.$t('review.daily_transaction'),
          fullServiceCoverage: this.$t('review.full_service_coverage'),
          coverage: this.$t('review.full_service_coverage_description')
        },
        {
          number: '1500+',
          unit: 'k',
          // unit: this.$t('review.thousands'),
          description: this.$t('review.monthly_payment_amount'),
          fullServiceCoverage: this.$t('review.financial_serivce'),
          coverage: this.$t('review.financial_serivce_description')
        },
        {
          number: '500+',
          unit: this.$t('review.average'),
          description: this.$t('review.active_merchants'),
          fullServiceCoverage: this.$t('review.integration'),
          coverage: this.$t('review.integration_description')
        }
      ],
      product: [
        {
          name: this.$t('product.customer.title'),
          id: 0,
          description: this.$t('product.customer.description'),
          img: '../assets/image/product1.jpg',
          feature: [
            {
              name: this.$t('product.customer.scan_for_order'),
              icon: '../assets/icon/scan.svg',
              id: 1
            },
            {
              name: this.$t('product.customer.ordering_process'),
              icon: '../assets/icon/scan.svg',
              id: 2
            },
            {
              name: this.$t('product.customer.search_food'),
              icon: '../assets/icon/scan.svg',
              id: 3
            },
            {
              name: this.$t('product.customer.payment'),
              icon: '../assets/icon/scan.svg',
              id: 4
            },
            {
              name: this.$t('product.customer.multiple_laguages'),
              icon: '../assets/icon/scan.svg',
              id: 5
            }
          ]
        },
        {
          name: this.$t('product.kiosk_order.title'),
          id: 1,
          description: this.$t('product.kiosk_order.description'),
          img: '../assets/image/product2.jpg',
          feature: [
            {
              name: this.$t('product.kiosk_order.search_food'),
              icon: '../assets/icon/scan.svg',
              id: 6
            },
            {
              name: this.$t('product.kiosk_order.clear_image'),
              icon: '../assets/icon/scan.svg',
              id: 7
            },
            {
              name: this.$t('product.kiosk_order.selec_food_and_modify_food'),
              icon: '../assets/icon/scan.svg',
              id: 8
            },
            {
              name: this.$t('product.kiosk_order.easy_payment_option'),
              icon: '../assets/icon/scan.svg',
              id: 9
            }
          ]
        },
        {
          name: this.$t('product.employee.title'),
          id: 2,
          description: this.$t('product.employee.description'),
          img: '../assets/image/product3.jpg',
          feature: [
            {
              name: this.$t('product.employee.table_management'),
              icon: '../assets/icon/scan.svg',
              id: 10

            },
            {
              name: this.$t('product.employee.booking_management'),
              icon: '../assets/icon/scan.svg',
              id: 11
            },
            {
              name: this.$t('product.employee.order_management'),
              icon: '../assets/icon/scan.svg',
              id: 12
            },
            {
              name: this.$t('product.employee.payment_management'),
              icon: '../assets/icon/scan.svg',
              id: 13
            }
          ]
        }
      ],
      product2: [
        {
          name: this.$t('product.back_office.title'),
          id: 3,
          description: this.$t('product.back_office.description'),
          img: '../assets/image/product4.jpg',
          feature: [
            {
              name: this.$t('product.back_office.dashboard'),
              icon: '../assets/icon/scan.svg',
              id: 14
            },
            {
              name: this.$t('product.back_office.kiosk_management'),
              icon: '../assets/icon/scan.svg',
              id: 15
            },
            {
              name: this.$t('product.back_office.cashier_system'),
              icon: '../assets/icon/scan.svg',
              id: 16
            },
            {
              name: this.$t('product.back_office.employee_management'),
              icon: '../assets/icon/scan.svg',
              id: 17
            },
            {
              name: this.$t('product.back_office.payment_management'),
              icon: '../assets/icon/scan.svg',
              id: 18
            },
            {
              name: this.$t('product.back_office.customer_facing_display'),
              icon: '../assets/icon/scan.svg',
              id: 19
            },
            {
              name: this.$t('product.back_office.multiple_branches/brands_management'),
              icon: '../assets/icon/scan.svg',
              id: 20
            },
            {
              name: this.$t('product.back_office.profit_managemnt'),
              icon: '../assets/icon/scan.svg',
              id: 21
            }
          ]
        },
        {
          name: this.$t('product.cashier_system.title'),
          id: 4,
          description: this.$t('product.cashier_system.description'),
          img: '../assets/image/product5.jpg',
          feature: [
            {
              name: this.$t('product.cashier_system.table_management'),
              icon: '../assets/icon/scan.svg',
              id: 22

            },
            {
              name: this.$t('product.cashier_system.booking_management'),
              icon: '../assets/icon/scan.svg',
              id: 23
            },
            {
              name: this.$t('product.cashier_system.dashboard'),
              icon: '../assets/icon/scan.svg',
              id: 24
            },
            {
              name: this.$t('product.cashier_system.order_management'),
              icon: '../assets/icon/scan.svg',
              id: 25
            },
            {
              name: this.$t('product.cashier_system.payment_management'),
              icon: '../assets/icon/scan.svg',
              id: 26

            }
          ]
        }
      ],
      service: [
        {
          img: 'service1.jpg',
          description: this.$t('seavices.food1')
        },
        {
          img: 'service2.jpg',
          description: this.$t('seavices.food2')
        },
        {
          img: 'service3.jpg',
          description: this.$t('seavices.food3')
        },
        {
          img: 'service4.jpg',
          description: this.$t('seavices.food4')
        },
        {
          img: 'service5.jpg',
          description: this.$t('seavices.food5')
        },
        {
          img: 'service6.jpg',
          description: this.$t('seavices.food6')
        },
        {
          img: 'service7.jpg',
          description: this.$t('seavices.food7')
        },
        {
          img: 'service8.jpg',
          description: this.$t('seavices.food8')
        }
      ],
      merchant: [
        'merchant1.jpg',
        'merchant2.jpg',
        'merchant3.jpg',
        'merchant4.jpg',
        'merchant5.jpg',
        'merchant6.jpg',
        'merchant7.jpg',
        'merchant8.jpg',
        'merchant9.jpg',
        'merchant10.jpg',
        'merchant11.jpg',
        'merchant12.jpg',
        'merchant13.jpg',
        'merchant14.jpg',
        'merchant15.jpg',
        'merchant16.jpg',
        'merchant17.jpg',
        'merchant18.jpg',
        'merchant19.jpg',
        'merchant20.jpg',
        'merchant22.jpg',
        'merchant23.jpg',
        'merchant24.jpg',
        'merchant25.jpg',
        'merchant26.jpg',
        'merchant27.jpg',
        'merchant28.jpg',
        'merchant29.jpg'
      ],
      food1: [
        'food1.jpg',
        'food2.jpg',
        'food3.jpg'
      ],
      food2: [
        'food4.jpg',
        'food5.jpg',
        'food6.jpg'
      ],
      food3: [
        'food7.jpg',
        'food8.jpg',
        'food9.jpg',
        'food10.jpg',
        'food11.jpg',
        'food12.jpg',
        'food13.jpg',
        'food14.jpg',
        'food15.jpg'
      ],
      food4: [
        'food16.jpg',
        'food17.jpg',
        'food18.jpg',
        'food19.jpg',
        'food20.jpg',
        'food1.jpg',
        'food22.jpg',
        'food23.jpg',
        'food24.jpg'
      ],
      showToast: false,
      message: ''
    }
  },
  computed: {
  },
  watch: {
    menuTxt: {
      handler: function(newVal, oldVa) {
        this.scrollTo(newVal)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    getImages(imgPath) {
      return require( '../assets/image/' + imgPath )
    },
    handleMessange(message) {
      this.message = message.message
      this.showToast = true
      setTimeout(() => {
        this.showToast = false
        this.message = ''
      }, 3000)
    },
    scrollTo(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@baseColor:#0F9D58;
.cover {
  position: relative;
  width: 100%;
  // height: 100vh ;
  padding-top: 50.5px;
  .video {
    width: 100%;
    // max-height: 100vh;
    object-fit: cover;
  }
  .cover-img {
    display: none;
  }

  @media screen and (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    .video {
        display: block;
        height: 100vh;
      }
      .cover-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
  }

  div {
    position: absolute;
    width: 100%;
    // background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    transform: translate(-50%, -50%);
    text-align: center;
    color: white;
    top: 50%;
    left: 50%;
    padding: 20px 0 30px 0;
    h1 {
      /* width: 50%; */
      margin: auto;
      /* font-size: 35px; */
      font-size: 50px;
      margin-bottom: 1rem;
      padding-bottom: 10px;
      font-weight: bold;
    }

    p {
      text-align: center;
      width: 50%;
      /* font-size: 18px; */
      font-size: 20px;
      margin: auto;
    }
  }
}
.about-us{
  width: 100%;
  padding: 70px 0 0 0;
  background-color: inherit;
  h3{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: @baseColor;
    padding-bottom: 20px;
  }
  h1{
    text-align: center;
    font-size: 40px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    width: 60%;
    margin: auto;
  }
}
.review{
  width: 100%;
  padding: 70px 0 120px 0 ;
  background-color: #FFFFFF;
  display: flex;
  gap: 20px;
  div{
    width: 30%;
    margin: auto;
    height: 40vh;
    h1 {
    text-align: center;
    font-size: 32px;
    font-weight: bold;
    white-space: nowrap;
    span {
      color: @baseColor;
      font-size: 100px;
    }
  }
  h4 {
    text-align: center;
    font-size: 42px;
    padding-top: 10px;
    color: @baseColor;
    white-space: nowrap;
  }
  p {
    text-align: center;
    width: 70%;
    /* font-size: 14px; */
    font-size: 18px;
    margin: auto;
    color: #888888;
  }
  }

}
.product{
  width: 100%;
  padding: 70px 0 0 0;
  background-color: #F7F1EA;
  h3{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: @baseColor;
    padding-bottom: 20px;
  }
  h1{
    text-align: center;
    font-size: 40px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    width: 60%;
    margin: auto;
  }
  .service-item {
  padding: 20px 200px 20px 200px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  align-items: center;
}
.service-item-two {
  padding: 20px 200px 20px 200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: -20px;
  align-items: center;
}

}
.service{
  padding: 70px 0 0 0 ;
    h3{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 50%;
    font-size: 40px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    margin: auto;
  }
  .grid-img{
    display: grid;
    max-width: 50%;
    margin: auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      position: relative;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .text-img{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        color:white;
        background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
        transform: translateX(-50%);
        text-align: center;
        color: white;
        bottom: 0;
        left: 50%;
        padding-bottom: 5px;
        p{
          margin: auto;
          font-size: 18px;
          font-weight: 500;
        }
      }
    }
  }

}
.merchant{
  background-color: #F7F1EA;
  padding: 70px 0 50px 0;
    h3{
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 70%;
    font-size: 40px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    margin: 10px auto;
  }
  .grid-img{
    display: grid;
    max-width: 60%;
    margin: auto;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p{
        text-align: center;
        font-size: 14px;
        color: #000;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 1;
      }
    }
  }

}
.floating-button {
  position: fixed;
  right: 20px;
  bottom: 60px;
  width: 110px;
  padding: 5px 5px 3px 7px;
  border-radius: 300px;
  background-color: #0F9D58;
  border: none;
  z-index: 1000; /* Ensure the button floats above other elements */
}

.button-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.button-content p {
  font-size: 16px;
  font-weight: bold;
  color: white;
  text-align: center;
}
.food{
  width: 100%;
  max-height: 450px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .food-bg{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .text{
    background: white;
    padding: 0 20px 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    h1{
      font-size: calc(20px + 1vw);
      color: #000;
      white-space: nowrap;
      font-weight: bold;
    }
  }
  @media screen and (max-width: 1000px){
    .text{
      h1{
        font-size: 20px;
      }
  }
}
@media screen and (max-width: 768px) {
  .text{
    h1{
      font-size: 10px;
    }
  }
}
}
.message{
  width: 100%;
  min-height: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .img{
    width: 100vw;
    min-height: 550px;
    background: #c91111;
  }
  .form{
    position: absolute;
    width: 50%;
    top: 50%;
    left: 35%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 800px){
  .message{
    min-height:483px;
      .img{
      width: 100vw;
      min-height: 483px;
      background: #c91111;
    }
    .form{
      width: 80%;
      left: 50%;
    }
  }
}
@media screen and (max-width: 768px) {
  .cover {
    div {
      h1 {
        /* font-size: 25px; */
        font-size: 35px;
        width: 80%;
      }
      p {
        font-size: 18px;
        width: 80%;
      }
    }
  }
}
@media screen and (max-width: 1200px){
  .about-us{
    h3{
      font-size: 20px;
    }
    h1{
      font-size: 30px;
    }
  }
  .review{
    flex-direction: column;
    div{
      width: 100%;
      margin: auto;
      height: 35vh;
      h1 {
        font-size: 20px;
        span{
          font-size: 60px;
        }
      }
      h4 {
        font-size: 20px;
      }
      p{
        font-size: 15px;
      }
    }
  }
  .product{
    h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 70%;
    font-size: 24px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    margin: auto;
  }
    .service-item-two {
    padding: 20px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: 60%;
    margin: -40px auto 0;
  }
  .service-item {
  width: 60%;
  padding: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 20px;
  align-items: center;
  margin: auto;
}
  }

  .service{
    h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 24px;
    color: #000;
    font-weight: 700;
    margin: 20px auto;
  }
  .grid-img{
    display: grid;
    max-width: 80%;
    margin: auto;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.merchant{
  h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 24px;
    color: #000;
    font-weight: 700;
    margin: 20px auto;
  }
  .grid-img{
    display: grid;
    max-width: 80%;
    margin: auto;
    grid-template-columns: repeat(7, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
}
@media screen and (max-width: 1400px){
  .review{
    div{
      width: 100%;
      margin: auto;
      h1 {
        font-size: 20px;
        span{
          font-size: 60px;
        }
      }
      h4 {
        font-size: 20px;
      }
      p{
        font-size: 15px;
      }
    }
  }
}
@media screen and (max-width: 850px){
  .about-us{
    h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 70%;
    font-size: 24px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    margin: auto;
  }
  }
  .review{
    flex-direction: column;
    div{
      width: 100%;
      margin: auto;
      h1 {
        font-size: 20px;
        span{
          font-size: 60px;
        }
      }
      h4 {
        font-size: 20px;
      }
      p{
        font-size: 15px;
      }
    }
  }
  .product{
    h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 70%;
    font-size: 24px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    margin: auto;
  }
    .service-item-two {
    padding: 20px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: 60%;
    margin: -40px auto 0;
  }
  .service-item {
  width: 60%;
  padding: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 20px;
  align-items: center;
  margin: auto;
}

  }
  .service{
    h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 20px;
    color: #000;
    font-weight: 700;
    margin: 20px auto;
  }
  .grid-img{
    display: grid;
    max-width: 80%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
.merchant{
  h3{
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 20px;
    color: #000;
    font-weight: 700;
    margin: 20px auto;
  }
  .grid-img{
    display: grid;
    max-width: 90%;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
}
@media screen and (max-width: 600px){
  .about-us{
    h3{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin: auto;
  }
  }
  .review{
    flex-direction: column;
    padding-bottom: 10px;
    div{
      width: 100%;
      margin: auto;
      h1 {
        font-size: 20px;
        span{
          font-size: 60px;
        }
      }
      h4 {
        font-size: 20px;
      }
      p{
        font-size: 15px;
      }
    }
  }
  .product{
    h3{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin: auto;
  }
    .service-item-two {
    padding: 20px 0 20px 0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    width: 100%;
    margin: -40px auto 0;
  }
  .service-item {
  width: 100%;
  padding: 20px 0 20px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin-top: 20px;
  align-items: center;
  margin: auto;
}
  }
  .service{
    h3{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 16px;
    margin-top: 20px;
    color: #000;
    font-weight: 700;
    margin:10px auto;
  }
  .grid-img{
    display: grid;
    max-width: 90%;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
.merchant{
  h3{
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    color: @baseColor;
  }
  h1{
    text-align: center;
    width: 90%;
    font-size: 16px;
    color: #000;
    font-weight: 700;
    margin: 20px auto;
  }
  .grid-img{
    display: grid;
    max-width: 90%;
    margin: auto;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin-top: 20px;
    .img{
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}
}
.toast {
  position: fixed;
  left: 50%;
  bottom: 85%;
  transform: translateX(-50%);
  background-color: #0f9d58de;
  color: #fff;
  padding: 10px 20px;
  border-radius: 20px;
  z-index: 1000;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

</style>

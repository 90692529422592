<template>
  <div>
    <div v-for="(item, index) in data" :key="index" class="product">
      <div class="divider">
        <img :src="getImages(item.id)" style="object-fit: cover;" alt="">
      </div>
      <div class="container" :style="{ backgroundColor: getTitleColor(index) }" >
        <h2>{{ item.name }}</h2>
        <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">{{item.description}}</p>
      </div>
      <div class="feature" :style="{ backgroundColor: getbodyColor(index), '--rows': colNum }">
        <div v-for="(i, index) in item.feature" :key="index" style="display: flex; gap: 10px;">
          <img style="width: 24px; height: 24px;" :src="getIcon(i.id)" alt="">
          <p >{{i.name}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Product',
  props: {
    data: Array,
    colNum: Number
  },
  computed: {
    myData() {
      return this.data // This will be reactive to changes in `data` prop
    }
  },
  methods: {
    getImages(name) {
      switch (name) {
        case 0:
          return require('../assets/image/product1.jpg')
        case 1:
          return require('../assets/image/product2.jpg')
        case 2:
          return require('../assets/image/product3.jpg')
        case 3:
          return require('../assets/image/product4.jpg')
        case 4:
          return require('../assets/image/product5.jpg')
        default:
          return require('../assets/image/product3.jpg')
      }
    },
    getTitleColor(index) {
      const colors = ['#0F9D58', '#F4B400', '#DB4437']
      return colors[index % colors.length]
    },
    getbodyColor(index) {
      const colors = ['#E5F3EB', '#F4B4001A', '#EDDEDA']
      return colors[index % colors.length]
    },
    getIcon(id) {
      switch (id) {
        case 1:
          return require('../assets/icon/icon1.svg')
        case 2:
          return require('../assets/icon/icon2.svg')
        case 3:
          return require('../assets/icon/icon3.svg')
        case 4:
          return require('../assets/icon/icon4.svg')
        case 5:
          return require('../assets/icon/icon5.svg')
        case 6:
          return require('../assets/icon/icon6.svg')
        case 7:
          return require('../assets/icon/icon7.svg')
        case 8:
          return require('../assets/icon/icon8.svg')
        case 9:
          return require('../assets/icon/icon9.svg')
        case 10:
          return require('../assets/icon/icon10.svg')
        case 11:
          return require('../assets/icon/icon11.svg')
        case 12:
          return require('../assets/icon/icon12.svg')
        case 13:
          return require('../assets/icon/icon13.svg')
        case 14:
          return require('../assets/icon/icon14.svg')
        case 15:
          return require('../assets/icon/icon15.svg')
        case 16:
          return require('../assets/icon/icon16.svg')
        case 17:
          return require('../assets/icon/icon17.svg')
        case 18:
          return require('../assets/icon/icon18.svg')
        case 19:
          return require('../assets/icon/icon19.svg')
        case 20:
          return require('../assets/icon/icon20.svg')
        case 21:
          return require('../assets/icon/icon21.svg')
        case 22:
          return require('../assets/icon/icon22.svg')
        case 23:
          return require('../assets/icon/icon23.svg')
        case 24:
          return require('../assets/icon/icon24.svg')
        case 25:
          return require('../assets/icon/icon25.svg')
        case 26:
          return require('../assets/icon/icon26.svg')
        default:
          return require('../assets/icon/icon1.svg')
      }
    }
  }
}
</script>
<style lang="less" scoped>
@baseColor:#0F9D58;
.product {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  overflow: hidden;
  .divider {
    display: flex;
    justify-content: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  .container {
    color: white;
    width: 100%;
    background-color: @baseColor;
    padding: 20px 20px 40px 20px;
    min-height: 130px;
    h2 {
      font-size: 18px;
      font-weight: bold;
    }
    p {
      width: 90%;
      font-size: 14px;
    }
  }
  .feature {
  padding: 20px 20px 20px 10px;
  display: grid; /* Change this to grid */
  grid-template-rows: repeat(4, 1fr); /* Create 4 rows */
  grid-template-columns: repeat(2, 1fr); /* Create 2 columns */
  grid-auto-flow: column; /* Change the direction that the grid items are placed */
  gap: 10px;
  background-color: #E5F3EB;
}

  .feature > div > p {
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media screen and (max-width: 1500px) {
    .feature {
  padding: 20px 20px 20px 10px;
  display: grid; /* Change this to grid */
  grid-template-rows: repeat(var(--rows), 1fr); /* Create 4 rows */
  grid-template-columns: repeat(1, 1fr); /* Create 2 columns */
  grid-auto-flow: column; /* Change the direction that the grid items are placed */
  gap: 10px;
  background-color: #E5F3EB;
}

  .feature > div > p {
    font-size: 14px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  }
}

</style>

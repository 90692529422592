<template>
  <div>
    <header class="header" :class="{ bgchange: bgChange }">
      <div class="container">
        <div class="logo-container" style="display: flex; align-items: center;">
          <a class="logo-link" href="/">
            <img src="../assets/icon/mpos-logo.svg" alt="">
          </a><br/><br/>
        </div>
        <div class="menu">
          <div
            v-for="(item, index) in menuItems"
            :key="index"
            :ref="index"
            :class="{ active: activeIndex === index }"
            @click="handleMenuClick(index)"
          >
            <p>{{ item }}</p>
          </div>
        </div>
        <nav class="nav">
          <div class="buttons">
            <el-dropdown
              class="changeLang"
              trigger="click"
              @command="changeLange"
            >
              <span class="el-dropdown-link">
                {{ lang }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
                <!-- <el-dropdown-item command="kh">ខ្មែរ</el-dropdown-item> -->
              </el-dropdown-menu>
            </el-dropdown>
            <i
              class="menu-icon el-icon-menu show-md-and-down"
              @click="menuShow = true"
            ></i>
          </div>
          <div @click="hanleOpenMenu" class="mobile-menu">
            <img v-if="!open" src="../assets/icon/menu.svg" alt="menu" />
            <img v-else src="../assets/icon/close-menu.svg" alt="menu" />
          </div>
            <div class="mobile-menu" v-if="open" style="position: fixed; bottom: 0; left: 0; background: #FFFFFFD9; width: 100vw; height: calc(100vh - 48px); display: flex; flex-direction: column;justify-content: center; align-items: center; gap: 30px">
              <div
                v-for="(item, index) in menuItems"
                :key="index"
                @click="handleMenuClick(index)"
                :class="{ active: activeIndex === index }"
              >
                <p>{{ item }}</p>
              </div>
            </div>
        </nav>
      </div>
    </header>
       <router-view :menuTxt="activeIndex"/>
    <footer class="footer">
      <div class="container">
        <!-- <div class="left-box">
            <div >
              <p>{{$t('footer.join_us_on')}} <span><a href="https://mos.me/mpos_cambodia" target="_blank" style="color: white; text-decoration: underline;"> {{$t('footer.mosapp_channel')}}</a></span> </p>
              <img class="img" src="../assets/image/mossApp-qr.png" alt="MPos" />
            </div>
            <div>
              <p>{{$t('footer.join_us_on')}} <span> <a href="https://t.me/mpos_cambodia" target="_blank" style="color: white; text-decoration: underline " >{{$t('footer.telegram_channel')}}</a></span> </p>
              <img class="img" src="../assets/image/telegramqr.png" alt="MPos" />
            </div>
        </div> -->
        <div class="right-box">
          <h3 style="font-weight: bold; padding: 10px 0 10px 0;">{{ $t('footer.contact_us') }}</h3>
          <ul class="right-box-top">
            <li v-for="(item, i) in footerRightList.top" :key="i">
              <img :src="item.icon" />
              <p v-html=" item.text"></p>
            </li>
          </ul>
          <!-- <div class="right-box-bottom">
            <a
              :href="item.href"
              target="_blank"
              v-for="(item, i) in footerRightList.botton"
              :key="i"
            >
              <img class="item-img" :src="item.icon" :alt="item.alt" />
            </a>
          </div> -->
        </div>
      </div>
      <div style="display: flex; justify-content: center; align-items: center; text-align: center; width: 100%;">
        © 2022 蔚可云 <a style="color: hsl(45, 4%, 79%); margin-left: 5px" target="_blank" rel="nofollow" href="https://beian.miit.gov.cn/" >闽ICP备2021005368号
          </a>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'layout',
  computed: {
    nav() {
      return [
        { name: '关于U-Pay', to: '/' },
        {
          name: '电子钱包',
          to: '/e-wallet',
          children: [
            { name: '成为用户', to: '/e-wallet#user' },
            { name: '邀请好友', to: '/e-wallet#invite' },
            { name: '使用说明', to: '/e-wallet#guide' }

            // { name: '优付商户', to: '/e-wallet/discover' }
          ]
        },
        // { name: '客户推荐', to: '/merchantstory' },
        // {
        //   name: '最新消息',
        //   to: '/news',
        //   children: [
        //     { name: '活动资讯', to: '/news#events' },
        //     { name: '媒体报导', to: '/news#press' }
        //   ]
        // },
        {
          name: '商户专区',
          to: '/merchants',
          children: [
            { name: '商户优势', to: '/merchants#benefit' },
            { name: '常见问题', to: '/merchants#FAQs' }
          ]
        },
        { name: '下载', to: '/download' },
        {
          name: '职业',
          to: '/career'
        }
      ]
    },
    footerLeftList() {
      return [
        [
          { name: '关于U-Pay', to: '/' },
          { name: '电子钱包', to: '/e-wallet' }
          // { name: '客户推荐', to: '/merchantstory' },
          // { name: '最新公告', to: '/news' }
        ]
        // [
        //   { name: '使用者条款', to: '/terms_of_service' },
        //   { name: '隐私权条款', to: '/privacy_policy' }
        // ]
      ]
    },
    footerRightList() {
      return {
        top: [
          {
            icon: require('@/assets/image/邮箱icon@2x.png'),
            text: '2097959158@qq.com'
          }
          // {
          //   icon: require('@/assets/image/电话icon@2x.png'),
          //   // text: '011 223 319 (Sales) <br/>  011 223 328 (After Sales)'
          //   text: '13860121368'
          // },
          // {
          //   icon: require('@/assets/image/地址icon@2x.png'),
          //   text: '厦门市思明区吕岭路1599号宝龙国际中心1505、1507单元'
          // }
        ],
        botton: [
          {
            icon: require('@/assets/icon/mosapp.svg'),
            alt: 'mosapp',
            href: 'https://mos.me/mpos_cambodia'
          },
          {
            icon: require('@/assets/icon/facebook.svg'),
            alt: 'facebook',
            href: 'https://www.facebook.com/mpos.cambodia/'
          },
          {
            icon: require('@/assets/icon/instagram.svg'),
            alt: 'instagram',
            href: 'https://www.instagram.com/mpos.cambodia/'
          },
          {
            icon: require('@/assets/icon/tiktok.svg'),
            alt: 'tiktok',
            href: 'https://www.tiktok.com/@mpos.cambodia?_t=8lR6prhWaNF&_r=1'
          },
          {
            icon: require('@/assets/icon/linkin.svg'),
            alt: 'linkin',
            href: 'https://www.linkedin.com/company/mpos-cambodia/'
          },
          {
            icon: require('@/assets/icon/x.svg'),
            alt: 'x',
            href: 'https://twitter.com/MPOS_Cambodia'
          }
          // { icon: require('@/assets/image/icon-wechat@2x.svg'), alt: 'wechat' }
        ]
      }
    },
    lang() {
      switch (this.$i18n.locale) {
        case 'zh':
          return '中文'
        case 'en':
          return 'English'
        case 'kh':
          return 'ខ្មែរ'
        default:
          this.changeLange('zh')
          return '中文'
      }
    },
    smollScreen() {
      return window.clientWidth < 1200
    }
  },
  data() {
    return {
      bgChange: true,
      menuShow: false,
      mapDialogVisible: false,
      mapIframeLoading: true,
      menuItems: [this.$t('menus.home'), this.$t('menus.our_product'), this.$t('menus.our_services'), this.$t('menus.our_merchant'), this.$t('menus.customer_support')],
      activeIndex: null,
      open: false
    }
  },
  mounted() {
    window.onscroll = () => {
      if (window.pageYOffset > 75) {
        this.bgChange = true
      } else {
        this.bgChange = true
      }
      // this.menuItems.forEach((item, index) => {
      //   const el = this.$refs[index]
      //   if (el && el instanceof HTMLElement) {
      //     // Proceed with getBoundingClientRect()
      //     const { top, bottom } = el.getBoundingClientRect()
      //     console.log(top[0])
      //     console.log(bottom)
      //     // Rest of the code...
      //   } else {
      //     console.warn(`Invalid ref element for section ${index}`)
      //   }
      // })
    }
    if (this.$i18n.locale === 'kh') {
      const body = document.querySelector('body')
      body.style.fontFamily =
        "'Battambang''"
      // body.style.wordBreak = 'break-all'
      // body.style.wordWrap = 'break-word'
    }
  },
  methods: {
    changeLange(lang) {
      this.$i18n.locale = lang
      if (lang === 'kh') {
        const body = document.querySelector('body')
        body.style.fontFamily =
          "'Battambang''"
      } else {
        const body = document.querySelector('body')
        body.style.fontFamily =
          "'Roboto'"
      }
      localStorage.setItem('locale', lang)
      window.location.reload()
    },
    showMapDialog() {
      this.$data.mapDialogVisible = true
      this.$nextTick(() => {
        this.$refs.mapIframe.onload = () => {
          this.$data.mapIframeLoading = false
        }
      })
    },
    handleMenuClick(index) {
      this.activeIndex = index
      this.open = false
    },
    hanleOpenMenu() {
      this.open = !this.open
    }
  }
}
</script>
<style lang="less">
.logo-link img {
  height: 33px;
}
.menu-list {
  .el-drawer:focus {
    outline: none;
  }
  .el-menu {
    border: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  .el-drawer__body {
    padding-top: 15px;
  }
  .el-drawer.rtl {
    width: 60% !important;
    .el-menu-item,
    .el-submenu__title {
      font-size: 15px;
      line-height: 50px;
      height: 50px;
      color: #333;
    }
    .el-submenu__icon-arrow {
      font-size: 12px;
    }
    .el-menu-item-group ul .el-menu-item {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
@bacecolor: #e60013;
.header {
  position: fixed;
  z-index: 999;
  width: 100%;
  flex: 1;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.08);
  transition: all 0.1s;
  button {
    width: 80px;
    height: 34px;
    outline: none;
    background: none;
    margin-left: 20px;
    border: 1px solid #ffffff;
    border-radius: 6px;
    line-height: 34px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      border-color: @bacecolor;
      background-color: @bacecolor;
      color: #fff;
      opacity: 0.8;
    }
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* height: 74px; */
    line-height: 1.5;
    color: #fff;
    .logo {
      height: 38px;
      width: 128px;
      background: url("../assets/icon/mpos-logo.svg") no-repeat;
      background-size: contain;
      color: rgba(0, 0, 0, 0);
      line-height: 38px;
    }
    @media screen and (max-width: 768px) {
        .logo {
        height: 28px;
        width: 100px;
        background: url("../assets/icon/mpos-logo.svg") no-repeat;
        background-size: contain;
        color: rgba(0, 0, 0, 0);
        line-height: 38px;
        }
    }
    .nav {
      display: flex;
      margin-left: 20px;
      .menu {
        display: flex;
        .menu-item-link {
          text-align: center;
          height: 74px;
          display: flex;
          align-items: center;
          padding: 0 15px;
          color: #fff;
        }
        .menu-item {
          display: flex;
          position: relative;
          flex-direction: column;
          align-items: center;
          .submenu {
            box-sizing: border-box;
            overflow: hidden;
            border-radius: 0 0 8px 8px;
            position: absolute;
            top: 74px;
            flex-direction: column;
            height: 0;
            min-width: 100px;
            transition: all 0.3s;
            background: rgba(255, 255, 255, 0.3);
            box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
            .submenu-item {
              line-height: 50px;
              text-align: center;
              a {
                padding: 0 10px;
                height: unset;
                white-space: nowrap;
                color: #fff;
              }
              &:hover {
                background: rgba(253, 230, 231, 0.6);
                a {
                  color: #666;
                }
              }
            }
          }
          &:hover {
            z-index: 9999;
            .submenu {
              display: block;
              height: auto;
              padding: 10px 0 15px;
            }
          }
        }
      }
      .nav-line {
        position: absolute;
        bottom: 0px;
        width: 50px;
        height: 2px;
        background: #ffffff;
      }
      .fade-enter-active,
      .fade-leave-active {
        transition: width 0.3s;
      }
      .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        width: 0;
      }
      .buttons {
        display: flex;
        align-items: center;
        margin-left: 15px;
        /deep/.changeLang {
          white-space: nowrap;
          margin-left: 20px;
          font-weight: 600;
          .el-dropdown-link {
            cursor: pointer;
            color: #fff;
          }
        }
        .menu-icon {
          display: none;
          margin-left: 15px;
          font-size: 28px;
          vertical-align: middle;
          cursor: pointer;
          &:hover {
            color: #ffe6e7;
          }
        }
        @media screen and (max-width: 768px) {
          .menu-icon {
            display: inline-block;
          }
        }
      }
      @media screen and (max-width: 1200px) {
        .menu .menu-item-link {
          padding: 100px 8px 0 0;
        }
        .buttons {
          margin-left: 0px;
        }
      }
      @media screen and (max-width: 992px) {
        .menu .menu-item-link {
          font-size: 13px;
          padding: 100px 8px 0 0;
        }
        .buttons {
          margin-left: 0px;
        }
      }
    }
  }
  .menu {
  color: black;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  width: 55%;
  padding: 14px 30px 0 50px;
  /* margin-left: 35px; */
}

.menu div {
  border-bottom: 2px solid transparent;
  height: 100%;
  padding-bottom: 12px;
  cursor: pointer;
}

.menu div p {
  font-size: 15px;
  font-weight: 600;
}

.menu div.active {
  border-bottom-color: #0F9D58;
  color: #0F9D58;
}
@media (max-width: 850px) {
  .menu {
    display: none;
  }
}

.mobile-menu {
  display: none;
  img {
    width: 30px;
    height: 30px;
  }
  div {
    color: #1E1E1E;
    font-weight: bold;
  }

  div.active {
    color: #0F9D58;
    font-weight: bold;
  }
}
@media  (max-width: 850px) {
  .mobile-menu {
    display: block;
  }
}
}
.bgchange {
  background: #ffffff;
  box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.1);
  .container,
  /* a {
    color: #333333 !important;
  } */
  .submenu {
    background: #ffffff !important;
  }
  .nav-line {
    background: #e60013 !important;
  }
  .logo {
    background-image: url("../assets/image/mpos-logo.png") !important;
  }
  button {
    color: #333333;
    border-color: #333333;
    &:hover {
      color: #fff;
      border-color: #e60013;
      opacity: 0.8;
    }
  }
  /deep/.changeLang {
    .el-dropdown-link {
      color: #333333 !important;
    }
  }
}
.footer {
  background-color: #22211f;
  padding: 30px 0 20px 0;
  font-size: 14px;
  color: hsl(45, 4%, 79%);
  position: relative;
  .container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 60px;
    a {
      transform: color 0.3s;
      &:hover {
        color: #fde6e7 !important;
      }
    }
    .left-box{
      display: flex;
      justify-content: center;
      align-items: center;

      div{
        display: flex; flex-direction: column; gap: 40px;
        justify-content: center;
        align-items: center;
        padding: 20px;

        p{
          font-weight: 700;
          color: white;
        }
         .img {
        width: 70%;
        height: auto;
        margin: auto;
      }
      }
    }
    @media screen and (max-width: 768px) {
      .left-box{
        gap: 5px;
        div{
          gap: 10px;
          padding: 5px;
        }
        p{
          font-weight: 500;
          font-size: 10px;
          text-align: center;
          span{
            white-space: nowrap;
          }
        }
      }
    }

    .right-box {
      .right-box-top {
        li {
          display: flex;
          margin-bottom: 13px;
          img {
            height: 30px;
            width: 30px;
            margin-right: 10px;
            border-radius: 50%;
            background: #393836;
          }
          p {
            margin-top: 5px;
            max-width: 349px;
            line-height: 20px;
          }
        }
        li:nth-child(3) p {
          margin-top: 0;
        }
      }
      .right-box-bottom {
        display: flex;
        gap: 5px;
        .item-img {
          margin-bottom: 20px;
          height: 28px;
          width: 28px;
          border-radius: 50%;
          background: #393836;
        }
        .wechat-code {
          cursor: pointer;
        }
      }
    }
  }
}

</style>
